<template>
  <div>
    <HeaderComponent></HeaderComponent>
    <Slider></Slider>
    <!-- <TeamCards></TeamCards>
    <FunFacts></FunFacts>
    <StackFilterStatic></StackFilterStatic>
    <Skills></Skills> -->
    <!-- <FooterComponent></FooterComponent> -->
  </div>
</template>

<script>
import HeaderComponent from "@/components/header-component.vue";
// import FooterComponent from "@/components/footer-component.vue";
import Slider from "@/components/slider.vue";
// import TeamCards from "@/components/team-cards.vue";
// import FunFacts from "@/components/fun-facts.vue";
// import Skills from "@/components/skills.vue";
  // import RecentProjectsStatic from "@/components/recent-projects-static.vue";
  // import VideoComponent from "@/components/video-component.vue";
  // import Portfolio from "@/components/portfolio.vue";
  // import FoundryCards from "@/components/foundry-cards.vue";
  // import Partner from "@/components/partner.vue";
  // import Contact from "@/components/contact.vue";
  // import BlogSection from "@/components/blog-section.vue";
  import styleService from "@/resources/style-service";
  // import BitServices from '../components/bit-services.vue';
  //
  export default {
    name: 'Home',
    components: {
      HeaderComponent,
      // FooterComponent,
      Slider,
      // TeamCards,
      // FunFacts,
      // Skills
      // VideoComponent,
      // Portfolio,
      // FoundryCards,
      // BitServices,
      // Partner,
      // Contact,
      // BlogSection,
      // RecentProjectsStatic,
    },
    mounted() {
      styleService.applyStyling();
    }
  };
</script>
