<template>
    <footer class="odd">

        <!-- Footer [links] -->
        <section id="footer" class="footer">
            <div class="container">
                <div class="row items footer-widget">
                    <div class="col-12 col-lg-3 p-0">
                        <div class="row">
                            <div class="branding col-12 p-3 text-center text-lg-left item">
                                <div class="brand">
                                    <a href="/" class="logo">
                                        <!-- The Bit Foundry. -->
                                        
                                           <!-- Custom Logo -->
                                            <img src="@/assets/img/brand/The-Bit-Foundry-thebitfoundry.png" alt="The Bit Foundry">
                                    </a>
                                </div>
                                <p>Orchestrating the success of brands, businesses, and communities through digital economics, impact, and excellence.
                                </p>

                                <ul class="navbar-nav social share-list mt-0 ml-auto">
                                    <li class="nav-item">
                                        <a href="https://www.instagram.com/thebitfoundry/" class="nav-link" target="_blank"><i class="icon-social-instagram ml-0"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="https://www.facebook.com/thebitfoundry/" class="nav-link" target="_blank"><i class="icon-social-facebook"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="https://www.youtube.com/channel/UCoAwvwL4m_JzDrDwU44ysvw/about" class="nav-link" target="_blank"><i class="icon-social-youtube"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="https://www.twitter.com/thebitgroup/" class="nav-link" target="_blank"><i class="icon-social-twitter"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-9 p-0">
                        <div class="row">
                            <div class="col-12 col-lg-4 p-3 text-center text-lg-left item">
                                <h4 class="title">get in touch</h4>
                                <ul class="navbar-nav">
                                    <!-- <li class="nav-item">
                                        <a href="tel:#" class="nav-link">
                                            <i class="icon-phone mr-2"></i>
                                            (+31) 
                                        </a>
                                    </li> -->
                                    <li class="nav-item">
                                        <a href="mailto:info@thebitfoundry.net" class="nav-link">
                                            <i class="icon-envelope mr-2"></i>
                                            info@thebitfoundry.net
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            <i class="icon-location-pin mr-2"></i>
                                                 around the world
                                                 <!-- <br/>
                                                 <span style="padding-left:28px;">South Africa, RSA 3610</span> -->
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="mailto:info@thebitfoundry.net" class="mt-4 mr-auto ml-auto ml-lg-0 btn dark-button"><i class="icon-speech"></i>email us</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-lg-4 p-3 text-center text-lg-left item">
                                <h4 class="title">tech & things</h4>
                                <ul class="navbar-nav">
                                        <li class="nav-item"><router-link to="/technical" onclick="makeItJazzy();"  class="nav-link">_tech</router-link></li>
                                         <li class="nav-item"><router-link to="/creative" onclick="makeItJazzy();"  class="nav-link">_& things</router-link></li>
                                </ul>
                            </div>
                            <div class="col-12 col-lg-4 p-3 text-center text-lg-left item">
                                <h4 class="title">industries</h4>
                                <a href="#" class="badge tag">YouTube</a>
                                <a href="#" class="badge tag">Software</a>
                                <a href="#" class="badge tag">Brand</a>
                                <a href="#" class="badge tag">Data</a>
                                <a href="#" class="badge tag">Media</a>
                                <a href="#" class="badge tag">Advertising</a>
                                <a href="#" class="badge tag">Business</a>
                                <a href="#" class="badge tag">Operations</a>
                                <a href="#" class="badge tag">Adventure</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Copyright -->
        <section id="copyright" class="p-3 copyright">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 p-3 text-center text-lg-left">
                        <p>© The Bit Foundry 2024</p>

                        
                        <!--
                            Suggestion: Replace the text above with a description of your website.
                         -->
                    </div>
                    <div class="col-12 col-md-6 p-3 text-center text-lg-right">
                        <p>made with ♥</p>
                    </div>
                </div>
            </div>
        </section>

    </footer>
</template>

<script>
    export default {
        name: 'footer-component'
    }
</script>
