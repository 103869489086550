<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
  //
  export default {
    name: 'App',
    watch:{
    $route (){
      window.location.reload();
    }
} 
  }
</script>

<style lang="scss">

</style>
