<template>
  <section id="slider" class="hero p-0 odd featured right">
    <div class="swiper-container no-slider animation slider-h-100">
      <div class="swiper-wrapper">
        <!-- Item 1 -->
        <div class="swiper-slide slide-center">
          <img
            data-aos="zoom-out-up"
            data-aos-delay="100"
            src="@/assets/img/pixel-art/TheBitFoundry-The-Bit-Foundry-Digital-Consulting-1500.png"
            class="hero-image"
            id="bit-bounce"
            alt="Hero Image"
          />
          <div class="slide-content row" data-mask-768="70">
            <div class="col-12 d-flex inner">
              <div class="left align-self-center text-center text-md-left">
                <h2
                  data-aos="zoom-out-up"
                  data-aos-delay="200"
                  class="title effect-static-text welcome-title"
                >
                  Welcome to<br />The Bit Foundry
                </h2>
                <p
                  data-aos="zoom-out-up"
                  data-aos-delay="500"
                  class="description"
                >
                We are a creative technology duo passionate about tech, family, music, adventure and making the most of this intriguing experience we call life.
                </p>
                <a
                  href="http://www.youtube.com/channel/UCoAwvwL4m_JzDrDwU44ysvw?sub_confirmation=1"
                  target="_blank"
                  data-aos="zoom-out-up"
                  data-aos-delay="600"
                  class="ml-auto mr-auto ml-md-0 mt-4 btn dark-button"
                  ><i class="icon-cup"></i>Subscribe on YouTube</a
                >
                <!-- <a href="mailto:info@thebitfoundry.net" data-aos="zoom-out-up" data-aos-delay="1200" class=" ml-auto mr-auto ml-md-0 mt-4 btn dark-button"><i class="icon-social-github"></i>Contact us</a> -->
                <!-- <a href="https://www.instagram.com/thebitfoundry/" target="_blank" data-aos="zoom-out-up" class="ml-auto mr-auto ml-md-0 mt-4 btn dark-button"><i class="icon-social-instagram"></i> @thebitfoundry</a>
                                <a href="https://www.facebook.com/thebitfoundry/" target="_blank" data-aos="zoom-out-up" class="ml-auto mr-auto ml-md-0 mt-4 btn dark-button"><i class="icon-social-facebook"></i> @thebitfoundry</a>
                                <a href="https://www.twitter.com/thebitgroup/" target="_blank" data-aos="zoom-out-up" class="ml-auto mr-auto ml-md-0 mt-4 btn dark-button"><i class="icon-social-twitter"></i> @thebitgroup</a> -->
                <!-- <li class="nav-item ml-auto mr-auto ml-md-0 mt-4 btn dark-button" data-aos="zoom-out-up" data-aos-delay="1200"><router-link to="/#/technical-services" onclick="makeItJazzy();" class="nav-link"><i class="icon-cup"></i>Bit Services</router-link></li> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "slider",
  mounted() {
    this.$forceUpdate();
  },
};
</script>

<style scoped>
.welcome-title {
  margin-top: 3rem !important;
  font-size: 5rem;
}

.hero-image {
  max-height: 800px;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-25px);
  }
}
#bit-bounce {
  animation: bounce 0.8s infinite alternate;
}
</style>
