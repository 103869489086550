<template>
  <div>
    <header-component></header-component>
    <Oops></Oops>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from "@/components/header-component.vue";
import FooterComponent from "@/components/footer-component.vue";
import Oops from "@/components/Oops.vue";
  //
  export default {
    name: 'Home',
    components: {
    HeaderComponent,
    FooterComponent,
    Oops
},
};
</script>
