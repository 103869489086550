export default {
    name: 'styleService',
    styleRoutes: [
        {
            routeSlug: "creative",
            theme: "theme-light-blue"
        },
        {
            routeSlug: "", //home page.
            theme: "theme-light-green"
        }],
    applyStyling: function() {
        let lastUrlSegment = document.location.href
            .substring(document.location.href
                .lastIndexOf('/') + 1);

        console.log("Current Route:", lastUrlSegment);

        let foundryThemeCss = "css/theme-light-green.css";

        this.styleRoutes.forEach(f => {
            if(lastUrlSegment === f.routeSlug) {
                foundryThemeCss = `css/${f.theme}.css`;
            }
        });

        document.getElementById("theme-css")
            .setAttribute('href', foundryThemeCss);
    }
}
