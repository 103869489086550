import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Team from '../views/Team.vue'
// import Blog from '../views/Blog'
// import BlogSingle from '../views/BlogSingle'
// import ContactPage from '../views/ContactPage'
// import Operations from '../views/Operations'
// import Technical from '../views/Technical'
import NotFoundTwo from '../views/NotFoundTwo'
// import Agency from '../views/Strategic-Brand-Agency'
// import StrategicBrandAgency from '../views/StrategicBrandAgency.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: ContactPage
  // },
  // {
  //   path: '/ops',
  //   name: 'ops',
  //   component: Operations
  // },
  // {
  //   path: '/tech',
  //   name: 'tech',
  //   component: Technical
  // },
  {
    path: '*',
    name: '404 not found',
    component: NotFoundTwo
  },
  // {
  //   path: '/strategic-brand-agency',
  //   name: 'agency',
  //   component: StrategicBrandAgency
  // },
  // {
  //   path: '/team',
  //   name: 'team',
  //   component: Team
  // },
  // {
  //   path: '/portfolio',
  //   name: 'portfolio',
  //   component: PortfolioPage
  // },
  // {
  //   path: '/blog',
  //   name: 'blog',
  //   component: Blog
  // },
  // {
  //   path: '/blogs/:slug',
  //   name: 'blogs',
  //   component: BlogSingle
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  // {
  //   path: '/portfolio/portfolio-item-template',
  //   name: 'portfolio item template',
  //   component: PortfolioItemTemplate
  // }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

export default router

