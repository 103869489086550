<template>
    <section id="slider" class="hero p-0 odd featured right">
        <div class="swiper-container no-slider animation slider-h-100">
            <div class="swiper-wrapper">

                <!-- Item 1 -->
                <div class="swiper-slide slide-center">
                    <img data-aos="zoom-out-up" src="@/assets/img/pixel-art/TheBitFoundry-The-Bit-Foundry-Digital-Consulting-1500.png" class="hero-image" id="bit-bounce" alt="Hero Image">
                    <div class="slide-content row mb-4" data-mask-768="70">
                        <div class="col-12 d-flex inner">
                            <div class="left align-self-center text-center text-md-left">
                                <h5 class="title effect-static-text welcome-title">Oops, you're way off the map... 404</h5>
                             
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="swiper-pagination"></div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Oops'
    }

</script>

<style scoped>

   .welcome-title {
    margin-top: 1rem !important;
    font-size: 3rem;
    }

    .hero-image {
    max-height: 300px;
    }

         
    @keyframes bounce { 0% { transform: translateY(0); } 100% { transform: translateY(-25px); } }
    #bit-bounce { animation: bounce 0.8s infinite alternate; }

</style>
